<template>
  <div class="common-layout">
    <el-container>
      <el-aside>
        <el-card shadow="always">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="设备" name="equipment">
              <!-- 按设备 -->
              <el-select
                  v-model="selectedDevices"
                  multiple
                  clearable
                  collapse-tags
                  placeholder="请选择设备"
                  popper-class="custom-header"
                  :max-collapse-tags="1"
                  :filter-method="filterEquipment"
                  filterable
              >
                <template #header>
                  <div class="header-container">
                    <el-checkbox
                        v-model="checkAllDevices"
                        :indeterminate="indeterminateDevices"
                        @change="handleCheckAllDevices"
                    >
                      全选
                    </el-checkbox>
                    <el-radio-group
                        v-model="energyType"
                        size="small"
                        class="radio-group-right"
                        @change="energyTypeHandleClick"
                    >
                      <el-radio-button label="电" value="电"/>
                      <el-radio-button label="水" value="水"/>
                    </el-radio-group>
                  </div>
                </template>
                <el-option
                    v-for="device in devices"
                    :key="device.id"
                    :label="device.name"
                    :value="device.id"
                />
              </el-select>
              <!-- 按设备end -->
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-aside>
      <el-main>
        <el-card shadow="always">
          <el-tabs
              v-model="activeNameTable"
              class="demo-tabs"
              @tab-click="handleClickTable"
          >
            <el-tab-pane label="日报" name="day">
              <div class="query-container">
                <el-select
                    v-model="energyType"
                    placeholder="选择用能类型"
                    style="width: 100px; margin-right: 10px"
                >
                  <el-option label="电" value="电"></el-option>
                  <el-option label="水" value="水"></el-option>
                </el-select>
                <el-date-picker
                    v-model="dateRange"
                    value-format="YYYY-MM-DD"
                    type="daterange"
                    range-separator="到"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    placeholder="选择日期范围"
                    style="margin-right: 10px"
                />
                <el-button type="primary" :icon="Search" @click="queryCustomRegionPeakValleyLevelDay">查询</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="月报" name="month">
              <div class="query-container">
                <el-select
                    v-model="energyType"
                    placeholder="选择用能类型"
                    style="width: 100px; margin-right: 10px"
                >
                  <el-option label="电" value="电"></el-option>
                  <el-option label="水" value="水"></el-option>
                </el-select>
                <el-date-picker
                    v-model="month"
                    type="month"
                    placeholder="选择月"
                    style="margin-right: 10px"
                />
                <el-button type="primary" :icon="ArrowLeft" @click="handlePreviousMonth">上一月</el-button>
                <el-button type="primary" @click="handleNextMonth">
                  下一月
                  <el-icon style="margin-left: 5px">
                    <ArrowRight/>
                  </el-icon>
                </el-button>
                <el-button type="primary" :icon="Search" @click="queryCustomRegionPeakValleyLevelMonth">查询</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="年报" name="year">
              <div class="query-container">
                <el-select
                    v-model="energyType"
                    placeholder="选择用能类型"
                    style="width: 100px; margin-right: 10px"
                >
                  <el-option label="电" value="电"></el-option>
                  <el-option label="水" value="水"></el-option>
                </el-select>
                <el-date-picker
                    v-model="year"
                    type="year"
                    placeholder="选择年"
                    style="margin-right: 10px"
                    value-format="YYYY"
                />
                <el-button type="primary" :icon="ArrowLeft" @click="handlePreviousYear">上一年</el-button>
                <el-button type="primary" @click="handleNextYear">
                  下一年
                  <el-icon style="margin-left: 5px">
                    <ArrowRight/>
                  </el-icon>
                </el-button>
                <el-button type="primary" :icon="Search" @click="queryCustomRegionPeakValleyLevelYear">查询</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>

          <!-- 图表弹窗 -->
          <el-dialog
              v-model="dialogVisible"
              title="电量/金额分析饼图"
              width="1000"
          >
            <el-row>
              <el-col :span="12">
                <el-card shadow="always">
                  <div>电量占比</div>
                  <el-divider></el-divider>
                  <div ref="electricityChart" style="height: 250px"></div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card shadow="always">
                  <div>金额占比</div>
                  <el-divider></el-divider>
                  <div ref="amountChart" style="height: 250px"></div>
                </el-card>
              </el-col>
            </el-row>
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="dialogVisible = false">
                  确定
                </el-button>
              </div>
            </template>
          </el-dialog>

          <!-- 表格 -->
          <el-table
              :data="currentTableData"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              show-selection
              show-summary
              :summary-method="getSummaries"
          >
            <el-table-column type="selection" width="55" align="center"/>

            <el-table-column prop="customRegionName" label="分组名称" align="center" sortable/>
            <el-table-column prop="startTime" label="开始时间" align="center" sortable/>
            <el-table-column prop="endTime" label="结束时间" align="center" sortable/>
            <el-table-column prop="startValue" label="开始值" align="center" sortable/>
            <el-table-column prop="endValue" label="结束值" align="center" sortable/>

            <el-table-column prop="energy" label="电量/kwh" align="center" sortable/>
            <el-table-column prop="cost" label="单价" align="center" sortable/>
            <el-table-column prop="money" label="金额" align="center" sortable/>
          </el-table>

        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import {ref, watch, onMounted} from "vue";
import {
  ArrowLeft,
  ArrowRight,
  Search,
} from "@element-plus/icons-vue";
import { ElMessage } from 'element-plus';
import apiModule from "@/APIConfig";
import ApiConfig from "@/APIConfig";
import axios from "axios";
// 组件引用
const tree = ref(null);
const checkAllDevices = ref("false"); // 新增: 设备全选
//分组数据
const treeData = ref([]);

// 过滤后的树数据
const filteredTreeData = ref(treeData.value);
//设备数据
const selectedDevices = ref([])
const indeterminateDevices = ref(false)
const energyType = ref('电')
const devices = ref([])
const devicesAll = ref([])

// 分组设备 标签页状态
const activeName = ref("equipment");
// 标签页状态
const activeNameTable = ref("day");

// 日期控件状态
const dateRange = ref("");
// const month = ref([]);
// const year = ref([]);
const month = ref(new Date()); // 默认当前月
const year = ref(new Date().getFullYear().toString()); // 默认当前年
const dialogVisible = ref(false);//图表对话框
// 用于存储选中的表格行
const selectedData = ref([]);
//图表
const electricityChart = ref(null);
const amountChart = ref(null);
//表格数据
const currentTableData = ref([])

//搜索数据
const filterEquipmentValue = ref();
//设备搜索自定义
const filterEquipment = (value) => {
  filterEquipmentValue.value = value
  devices.value = devicesAll.value.filter(device => device.name.includes(value))
}


// 全选和取消全选逻辑
const handleCheckAllDevices = (val) => {
  selectedDevices.value = val ? devices.value
      .map(device => device.id) : []
  indeterminateDevices.value = false
}
//切换水电
const energyTypeHandleClick = (val) => {
  fetchDeviceData(val);
}


// 切换标签页时触发
const handleClick = async () => {

};
// 年月日切换标签页时触发
const handleClickTable = async () => {

};
// 处理上一月
const handlePreviousMonth = () => {
  const currentMonth = new Date(month.value);
  currentMonth.setMonth(currentMonth.getMonth() - 1);
  month.value = currentMonth;
};

// 处理下一月
const handleNextMonth = () => {
  const currentMonth = new Date(month.value);
  currentMonth.setMonth(currentMonth.getMonth() + 1);
  month.value = currentMonth;
};

// 处理上一年
const handlePreviousYear = () => {
  year.value = (parseInt(year.value) - 1).toString();
};

// 处理下一年
const handleNextYear = () => {
  year.value = (parseInt(year.value) + 1).toString();
};
const getSummaries = ({columns, data}) => {
  const sums = [];
  columns.forEach((column, index) => {
    if (column.property === 'cost') {
      sums[index] = ''; // 排除 cost 列，不参与汇总
      return;
    }

    // 对于其他需要汇总的列进行求和并保留三位小数
    const values = data.map(item => Number(item[column.property]));
    if (!isNaN(values[0])) {
      const sum = values.reduce((prev, curr) => {
        const value = Number(curr);
        return !isNaN(value) ? prev + value : prev;
      }, 0);

      // 保留三位小数
      sums[index] = sum.toFixed(3);
    } else {
      sums[index] = '';
    }
  });
  return sums;
};

// 表格选中项变化时触发
const handleSelectionChange = (rows) => {
  selectedData.value = rows
};




//日报查询
const queryCustomRegionPeakValleyLevelDay = async () => {
  const startTime = dateRange.value[0] + " " + "00:00:00";
  const endTime = dateRange.value[1] + " " + "23:59:59";

  const energyType1 = energyType.value;
  if (activeName.value === "group") {
    const customRegionData = tree.value.getCheckedNodes(true)
    // 提取 ID 集合
    const checkedNodeIds = customRegionData.map(node => node.id);
    const params = {
      "customRegionIds": checkedNodeIds,
      "endTime": endTime,
      "energyType": energyType1,
      "startTime": startTime
    }
    await fetchPeakValleyLevelDataCustomRegionPeak(params);
  } else if (activeName.value === "equipment") {
    const params = {
      "equipmentIds": selectedDevices.value,
      "endTime": endTime,
      "energyType": energyType1,
      "startTime": startTime
    }
    await fetchPeakValleyLevelDataEquipment(params);
  }

}
//月报查询
// 格式化为YYYY-MM-DD HH:MM:SS
const formatDateToFullString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份补0
  const day = date.getDate().toString().padStart(2, '0');          // 天补0
  const hours = date.getHours().toString().padStart(2, '0');        // 小时补0
  const minutes = date.getMinutes().toString().padStart(2, '0');    // 分钟补0
  const seconds = date.getSeconds().toString().padStart(2, '0');    // 秒补0
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
const queryCustomRegionPeakValleyLevelMonth = async () => {
  // 获取当前月份的第一天
  const startOfMonth = new Date(month.value.getFullYear(), month.value.getMonth(), 1);
  // 获取当前月份的最后一天
  const endOfMonth = new Date(month.value.getFullYear(), month.value.getMonth() + 1, 0);
  const startTime = formatDateToFullString(startOfMonth);
  let endTime = formatDateToFullString(endOfMonth);
  const date = endTime.split(' ');
  endTime = date[0] + " " + "23:59:59";

  const energyType1 = energyType.value;
  if (activeName.value === "group") {
    const customRegionData = tree.value.getCheckedNodes(true)
    // 提取 ID 集合
    const checkedNodeIds = customRegionData.map(node => node.id);
    const params = {
      "customRegionIds": checkedNodeIds,
      "endTime": endTime,
      "energyType": energyType1,
      "startTime": startTime
    }
    await fetchPeakValleyLevelDataCustomRegionPeak(params);
  } else if (activeName.value === "equipment") {
    const params = {
      "equipmentIds": selectedDevices.value,
      "endTime": endTime,
      "energyType": energyType1,
      "startTime": startTime
    }
    await fetchPeakValleyLevelDataEquipment(params);
  }

}
//年报查询
const queryCustomRegionPeakValleyLevelYear = async () => {
  const startTime = year.value + "-01-01 00:00:00";
  const endTime = year.value + "-12-31 23:59:59";

  const energyType1 = energyType.value;
  if (activeName.value === "group") {
    const customRegionData = tree.value.getCheckedNodes(true)
    // 提取 ID 集合
    const checkedNodeIds = customRegionData.map(node => node.id);
    const params = {
      "customRegionIds": checkedNodeIds,
      "endTime": endTime,
      "energyType": energyType1,
      "startTime": startTime
    }
    await fetchPeakValleyLevelDataCustomRegionPeak(params);
  } else if (activeName.value === "equipment") {
    const params = {
      "equipmentIds": selectedDevices.value,
      "endTime": endTime,
      "energyType": energyType1,
      "startTime": startTime
    }
    await fetchPeakValleyLevelDataEquipment(params);
  }

}
// 页面加载时触发接口调用
onMounted(async () => {
  await fetchDeviceData("电");
  await getAlllocation();
});
//获取设备
const fetchDeviceData = async (energyType) => {
  try {
    let params = {
      energyType: energyType,
      projectId: ApiConfig.projectId,
    };
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.getAllSwitchingTableEquipment}`, {
      params: params,  // 通过 params 字段传递查询参数
      headers: {
        'Content-Type': 'application/json',
      },
    });
    devices.value = response.data.data
    devicesAll.value = response.data.data
    return response.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
//返回结果转换树结构
const convertToTree = (data) => {
  const map = {};
  data.forEach((item) => {
    map[item.id] = {...item, label: item.name}; // 确保使用正确的属性名称
  });

  const tree = [];
  data.forEach((item) => {
    if (item.parent_id === null) {
      tree.push(map[item.id]);
    } else {
      const parent = map[item.parent_id];
      if (parent) {
        parent.children = parent.children || [];
        parent.children.push(map[item.id]);
      } else {
        // 如果没有找到父对象，将 parent_id 设为 null
        map[item.id].parent_id = null;
        tree.push(map[item.id]);
      }
    }
  });

  return tree;
};

//获取分组
const getAlllocation = async () => {
  try {
    const response = await fetch(`${apiModule.apiBaseUrl}/${apiModule.endpoints.getAllFixedCustomRegion}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    treeData.value = convertToTree(data.data);
    filteredTreeData.value = treeData.value;
    return data.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
//固定查询(分组)
const fetchPeakValleyLevelDataCustomRegionPeak = async (params) => {
  try {
    const response = await axios.post(`${apiModule.apiBaseUrl}/${apiModule.endpoints.queryCustomRegionFixed}`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const responseData = response.data.data;
    const queryCustomRegionFixedData = responseData.flatMap(item => item.queryCustomRegionFixedList || []);
    const popUpPrompt = responseData[0]?.popUpPrompt;
// 将提取的数据赋值给 currentTableData
    currentTableData.value = queryCustomRegionFixedData;

    if (popUpPrompt ==="设备数据异常") {
      ElMessage({
        message: '设备数据异常！',
        type: 'error',
        duration: 3000 // 2秒后自动消失
      });
    } else {
      if (popUpPrompt!=null){
        ElMessage({
          message: popUpPrompt,
          type: 'success',
          duration: 3000 // 2秒后自动消失
        });
      }
    }
// 返回结果（如果需要）
    return queryCustomRegionFixedData;

  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
//固定查询(设备)
const fetchPeakValleyLevelDataEquipment = async (params) => {
  try {
    currentTableData.value =[];
    const response = await axios.post(`${apiModule.apiBaseUrl}/${apiModule.endpoints.querySwitchingTableEquipmentFixed}`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // 直接从 response.data 中获取数据
    const responseData = response.data.message;
    if (responseData.includes("内无换表记录")){
      return  ElMessage({
        message: response.data.message,
        type: 'warning',
        duration: 3000 // 2秒后自动消失
      });
    }
   const queryEquipmentFixedList=response.data.data
    const queryCustomRegionFixedData = queryEquipmentFixedList.flatMap(item => item.queryEquipmentFixedList || []);
// 将提取的数据赋值给 currentTableData
    currentTableData.value = queryCustomRegionFixedData;
// 返回结果（如果需要）
    return queryCustomRegionFixedData;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};

// 当 dialogVisible 变化时初始化图表
watch(dialogVisible, async () => {

});
</script>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  font-size: 32px;
  font-weight: 600;
}

.query-container {
  text-align: left;
  margin-bottom: 20px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
