        <!-- /*
                           _ooOoo_
                          o8888888o
                          88" . "88
                          (| -_- |)
                          O\  =  /O
                       ____/`---'\____
                     .'  \\|     |//  `.
                    /  \\|||  :  |||//  \
                   /  _||||| -:- |||||-  \
                   |   | \\\  -  /// |   |
                   | \_|  ''\---/''  |   |
                   \  .-\__  `-`  ___/-. /
                 ___`. .'  /--.--\  `. . __
              ."" '<  `.___\_<|>_/___.'  >'"".
             | | :  `- \`.;`\ _ /`;.`/ - ` : | |
             \  \ `-.   \_ __\ /__ _/   .-` /  /
        ======`-.____`-.___\_____/___.-`____.-'======
                           `=---='
        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
                 佛祖保佑       永无BUG

                 环比分析页面
        */ -->
        <template>
  <div class="energy-report-container">
    <el-row :gutter="20">
      <!-- 左侧树控件 -->
      <el-col :span="6">
        <el-card>
          <template v-slot:header>
            <span class="card-header">{{apiModule.projectName}} 环比分析</span>
          </template>
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="分组" name="group">
              <!-- 按分组 -->
              <!-- 添加搜索输入框 -->
              <el-input
                placeholder="模糊搜索"
                v-model="searchQuery"
                class="tree-search"
                clearable
              />
              <el-tree
                ref="tree"
                :data="treeData"
                :props="defaultProps"
                highlight-current
                node-key="id"
                default-expand-all
                :expand-on-click-node="false"
                show-checkbox
                @check-change="handleCheckChange"
                :filter-node-method="filterNode"
              />
              <!-- 按分组end -->
            </el-tab-pane>

            <el-tab-pane label="设备" name="equipment">
              <!-- 按设备 -->
              <el-select
                v-model="selectedDevices"
                multiple
                clearable
                collapse-tags
                placeholder="请选择设备"
                popper-class="custom-header"
                :max-collapse-tags="1"
                :filter-method="filterEquipment"
                filterable
              >
                <template #header>
                  <div class="header-container">
                    <el-checkbox
                      v-model="checkAllDevices"
                      :indeterminate="indeterminateDevices"
                      @change="handleCheckAllDevices"
                    >
                      全选
                    </el-checkbox>
                    <el-radio-group
                      v-model="queryForm.energyType"
                      size="small"
                      class="radio-group-right"
                      @change="fetchDeviceData"
                    >
                      <el-radio-button label="电" value="电" />
                      <el-radio-button label="水" value="水" />
                    </el-radio-group>
                  </div>
                </template>
                <el-option
                  v-for="device in devices"
                  :key="device.id"
                  :label="device.name"
                  :value="device.id"
                />
              </el-select>
              <!-- 按设备end -->
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>

      <!-- 右侧查询条件和表格 -->
      <el-col :span="18">
        <div class="query-container">
          <!-- 查询条件 -->
          <el-form :model="queryForm" inline>
            <el-form-item class="left-align">
              <el-select
                v-model="queryForm.energyType"
                placeholder="选择用能类型"
                style="width: 120px; margin-right: 10px"
                @change="fetchDeviceData"
              >
                <el-option label="水" value="水"></el-option>
                <el-option label="电" value="电"></el-option>
              </el-select>
              <el-select
                v-model="queryForm.granularity"
                placeholder="选择颗粒度"
                style="width: 120px; margin-right: 10px"
                @change="handleGranularityChange"
              >
                <el-option label="日" value="day"></el-option>
                <el-option label="周" value="week"></el-option>
                <el-option label="月" value="month"></el-option>
                <el-option label="年" value="year"></el-option>
              </el-select>
              <el-date-picker
                v-model="queryForm.date"
                :type="datePickerType"
                placeholder="选择日期"
                style="width: 150px; margin-right: 10px"
                @change="handleDateChange"
              />
              <el-button
                type="primary"
                @click="fetchTableData"
                style="margin-left: 10px"
                >查询</el-button
              >
              <el-button type="primary" @click="exportToExcel">导出</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          border
        >
          <el-table-column prop="groupName" :label="nameLabel" fixed sortable />
          <el-table-column prop="currentUsage" :label="currentLabel" sortable />
          <el-table-column prop="previousUsage" :label="previousLabel" sortable />
          <el-table-column prop="increment" :label="`用量增/减(${unit})`" sortable>
            <template #default="{ row }">
              <span
                :style="{
                  color:
                    parseFloat(row.increment) < 0
                      ? 'green'
                      : parseFloat(row.increment) > 0
                      ? 'red'
                      : 'black',
                }"
              >
                {{ row.increment }} {{ unit }}
                <img
                  v-if="parseFloat(row.increment) !== 0"
                  :src="parseFloat(row.increment) < 0 ? downSvgPath : upSvgPath"
                  class="icon"
                  width="20"
                  height="20"
                />
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="ratio" label="环比%"  sortable>
            <template #default="{ row }">
              <span
                :style="{
                  color:
                    parseFloat(row.ratio) < 0
                      ? 'green'
                      : parseFloat(row.ratio) > 0
                      ? 'red'
                      : 'black',
                }"
              >
                {{ row.ratio }}%
                <img
                  v-if="parseFloat(row.ratio) !== 0"
                  :src="parseFloat(row.ratio) < 0 ? downSvgPath : upSvgPath"
                  class="icon"
                  width="20"
                  height="20"
                />
              </span>
            </template>
          </el-table-column>

          <el-table-column label="图表">
            <template #default="scope">
              <el-button @click="showChart(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <!-- 图表对话框 -->
    <el-dialog v-model="chartVisible" width="50%" :draggable="true">
      <template #title>
        {{ currentGroupName }}
      </template>
      <div class="current">
        <span>用量增/减: {{ currentIncrement }}  {{ unit }}</span>
        <span>环比%: {{ currentRatio }}%</span>
      </div>
      <v-chart ref="chartRef" :option="chartOptions" style="height: 400px" />
    </el-dialog>
  </div>
</template>
          
          <script>
import { ref, onMounted, nextTick } from "vue";
import axios from "axios";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { ElMessage } from "element-plus";
import "element-plus/dist/index.css";
import ApiConfig from "@/APIConfig.js";
import downSvg from "@/assets/svg/down.svg";
import upSvg from "@/assets/svg/up.svg";
import apiModule from "@/APIConfig.js";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
]);

export default {
  name: "EnergyComparison",
  computed: {
    apiModule() {
      return apiModule
    }
  },
  data() {
    return {
      searchQuery: "", // 搜索查询
      nameLabel: "分组名称", // 动态表格列标题
      devices: [], // 新增: 设备数据
      checkAllDevices: false, // 新增: 设备全选
      indeterminateDevices: false, // 新增: 设备全选不确定状态
      devicesAll:[]
    //   unit: "kW·h", // 初始化单位
    };
  },
  watch: {
    searchQuery(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.fetchDeviceData(); // 新增: 调用设备数据的获取方法
  },
  methods: {
    //设备搜索自定义
    filterEquipment(value){
      this.devices = this.devicesAll.filter(device => device.name.includes(value))
    },
    handleClick() {
      if (this.activeName === "group") {
        this.nameLabel = "设备名称";
      } else if (this.activeName === "equipment") {
        this.nameLabel = "分组名称";
      }
    },
    handleCheckAllDevices(val) {
      this.selectedDevices = val ? this.devices.map((device) => device.id) : [];
      this.indeterminateDevices = false;
    },
    async fetchDeviceData() {
      let params = {};
      params = {
        energyType: this.queryForm.energyType, 
        projectId: ApiConfig.projectId,
      };
      // 在切换用能类型之前清空已选设备
      this.selectedDevices = [];
      this.checkAllDevices = false;
      this.indeterminateDevices = false;
      try {
        const response = await axios.get(
          ApiConfig.apiBaseUrl + ApiConfig.endpoints.getAllequipment,
          { params: params }
        );
        if (response.data.code === 0) {
          this.devices = response.data.data;
          this.devicesAll = response.data.data;
        } else {
          this.$message.error("获取设备数据失败");
        }
      } catch (error) {
        this.$message.error("获取设备数据失败");
        console.error(error);
      }
    },
  },
  setup() {
    const selectedDevices = ref([]);
    const currentIncrement = ref(""); // 定义用量增/减
    const currentRatio = ref(""); // 定义环比%
    const downSvgPath = ref(downSvg);
    const upSvgPath = ref(upSvg);
    const activeName = ref("group");
    const energyType = ref("电");
    const granularity = ref("日");
    const date = ref(null);
    const treeData = ref([]);
    const defaultProps = ref({
      children: "children",
      label: "name",
    });
    const queryForm = ref({
      energyType: "电",
      granularity: "day",
      date: null,
    });
    const tableData = ref([]);
    const chartVisible = ref(false);
    const chartOptions = ref({});
    const loading = ref(false);
    const selectedNodes = ref([]);
    const currentLabel = ref("");
    const previousLabel = ref("");
    const chartRef = ref(null);
    const tree = ref(null);
    const currentGroupName = ref("");

    const datePickerType = ref("date");
    const unit = ref("kW·h");

    const transformTreeData = (data) => {
      const idMapping = data.reduce((acc, el, i) => {
        acc[el.id] = i;
        return acc;
      }, {});
      let root = [];
      data.forEach((el) => {
        if (el.parent_id === null) {
          root.push(el);
          return;
        }
        const parentEl = data[idMapping[el.parent_id]];
        parentEl.children = [...(parentEl.children || []), el];
      });
      return root;
    };

    const fetchTreeData = async () => {
      try {
        loading.value = true;
        const response = await axios.get(
          ApiConfig.apiBaseUrl + ApiConfig.endpoints.getAlllocation
        );
        treeData.value = transformTreeData(response.data.data);
      } catch (error) {
        ElMessage.error("获取区域数据失败");
      } finally {
        loading.value = false;
      }
    };

    const filterNode = (value, data) => {
      // 节点过滤方法：如果搜索值为空，显示所有节点；否则按节点名进行模糊匹配
      if (!value) {
        data.disabled = false; // 清空搜索时所有节点可见且可选
        return true;
      }
      const isMatch = data.name.includes(value);
      data.disabled = !isMatch; // 未匹配的节点设置为不可选
      return isMatch; // 返回 true 时显示节点，返回 false 时隐藏
    };
    const handleCheckChange = () => {
      selectedNodes.value = tree.value.getCheckedNodes().map((node) => ({
        id: node.id,
        label: node.name,
      }));
    };

    const fetchTableData = async () => {
      let apiurl;
      let requestData = {}; // 初始化 requestData
      unit.value = queryForm.value.energyType === "水" ? "m³" : "kW·h";
      if (activeName.value === "group") {
        if (selectedNodes.value.length === 0) {
          ElMessage.warning("请先选择一个或多个分组");
          return;
        }
        apiurl = ApiConfig.endpoints.getMonthOnMonth;
        requestData.customRegionIds = selectedNodes.value.map(
          (node) => node.id
        );
      } else if (activeName.value === "equipment") {
        if (selectedDevices.value.length === 0) {
          ElMessage.warning("请先选择一个或多个设备");
          return;
        }
        apiurl = ApiConfig.endpoints.getEquipmentMonthOnMonth;
        requestData.equipmentIds = selectedDevices.value;
      }
      const url = `${ApiConfig.apiBaseUrl}${apiurl}`;
      console.log("Request URL:", url);
      console.log("Request Data:", requestData);

      try {
        loading.value = true;
        const formattedDate = formatDate(
          queryForm.value.date,
          queryForm.value.granularity
        );
        const response = await axios.post(url, {
          ...requestData, // 将requestData展开传入
          energyType: queryForm.value.energyType,
          granularity: queryForm.value.granularity,
          date: formattedDate,
        });
        const data = response.data.data;
        tableData.value = data.map((item) => ({
          groupName: item.customRegionName,
          currentUsage: item.currentValue,
          previousUsage: item.previousValue,
          increment: item.increaseRate,
          ratio: item.compareRate,
        }));
        updateLabels();
      } catch (error) {
        ElMessage.error("获取数据失败");
      } finally {
        loading.value = false;
      }
    };

    const updateLabels = () => {
      const unit = queryForm.value.energyType === "水" ? "m³" : "kW·h";
      if (queryForm.value.granularity === "day") {
        currentLabel.value = `当日用量/${unit}`;
        previousLabel.value = `昨日用量/${unit}`;
      } else if (queryForm.value.granularity === "week") {
        currentLabel.value = `本周用量/${unit}`;
        previousLabel.value = `上周用量/${unit}`;
      } else if (queryForm.value.granularity === "month") {
        currentLabel.value = `本月用量/${unit}`;
        previousLabel.value = `上月用量/${unit}`;
      } else if (queryForm.value.granularity === "year") {
        currentLabel.value = `当年用量/${unit}`;
        previousLabel.value = `去年用量/${unit}`;
      }
    };

    const showChart = (row) => {
      const unit = queryForm.value.energyType === "水" ? "m³" : "kW·h";
      currentGroupName.value = row.groupName; // 设置当前组名
      currentIncrement.value = row.increment; // 设置当前用量增/减值
      currentRatio.value = row.ratio; // 设置当前环比%值
      console.log("Current group name:", currentGroupName.value);
      chartVisible.value = true;
      chartOptions.value = {
        title: {
          // text: `${row.groupName}`,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [currentLabel.value, previousLabel.value],
        },
        xAxis: {
          type: "category",
          data: ["用量"],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: `{value} ${unit}`,
          },
        },
        series: [
          {
            name: currentLabel.value,
            type: "bar",
            data: [row.currentUsage],
          },
          {
            name: previousLabel.value,
            type: "bar",
            data: [row.previousUsage],
          },
        ],
      };
      nextTick(() => {
        chartRef.value.resize();
      });
    };

    const handleGranularityChange = () => {
      if (queryForm.value.granularity === "day") {
        datePickerType.value = "date";
      } else if (queryForm.value.granularity === "week") {
        datePickerType.value = "week";
      } else if (queryForm.value.granularity === "month") {
        datePickerType.value = "month";
      } else if (queryForm.value.granularity === "year") {
        datePickerType.value = "year";
      }
    };

    const handleDateChange = (value) => {
      queryForm.value.date = value;
    };

    const formatDate = (date, granularity) => {
      if (!date) return "";
      const d = new Date(date);
      if (granularity === "week") {
        const weekNumber = getWeekNumber(d);
        return `${d.getFullYear()}w${weekNumber}`;
      }
      if (granularity === "month") {
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
          2,
          "0"
        )}`;
      }
      if (granularity === "year") {
        return `${d.getFullYear()}`;
      }
      if (granularity === "day") {
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const day = String(d.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`; // Manually format date to YYYY-MM-DD without timezone issues
      }
      return date;
    };

    const getWeekNumber = (date) => {
      const startDate = new Date(date.getFullYear(), 0, 1);
      const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
      return Math.ceil((days + startDate.getDay() + 1) / 7);
    };

    onMounted(() => {
      fetchTreeData();
    });

    const exportToExcel = async () => {
        if (
        (activeName.value === "group" && selectedNodes.value.length === 0) ||
        (activeName.value === "equipment" && selectedDevices.value.length === 0)
      ) {
        ElMessage.warning("请先选择一个或多个分组或设备");
        return;
      }

      try {
        loading.value = true;
        const formattedDate = formatDate(
          queryForm.value.date,
          queryForm.value.granularity
        );
        const granularityNameMap = {
          day: "日",
          week: "周",
          month: "月",
          year: "年",
        };
        const granularityName =
          granularityNameMap[queryForm.value.granularity] || "";

        let apiurl = "";
        let requestData = {};

        if (activeName.value === "group") {
          apiurl =
            ApiConfig.apiBaseUrl + ApiConfig.endpoints.getMonthOnMonthExcel;
          requestData = {
            customRegionIds: selectedNodes.value.map((node) => node.id),
          };
        } else if (activeName.value === "equipment") {
          apiurl =
            ApiConfig.apiBaseUrl +
            ApiConfig.endpoints.getEquipmentMonthOnMonthExcel;
          requestData = {
            equipmentIds: selectedDevices.value,
          };
        }

        const response = await axios.post(
          apiurl,
          {
            ...requestData,
            energyType: queryForm.value.energyType,
            granularity: queryForm.value.granularity,
            date: formattedDate,
          },
          { responseType: "blob" }
        );

        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const fileName = `${granularityName}环比数据_${formattedDate}.xlsx`;

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(downloadUrl);
          document.body.removeChild(link);
        }
      } catch (error) {
        ElMessage.error("导出数据失败");
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    return {
      activeName,
      treeData,
      selectedDevices,
      defaultProps,
      queryForm,
      tableData,
      currentLabel,
      previousLabel,
      chartVisible,
      chartOptions,
      loading,
      selectedNodes,
      fetchTableData,
      showChart,
      handleCheckChange,
      updateLabels,
      chartRef,
      tree,
      datePickerType,
      handleGranularityChange,
      handleDateChange,
      formatDate,
      getWeekNumber,
      currentGroupName,
      energyType,
      granularity,
      date,
      exportToExcel,
      downSvgPath,
      upSvgPath,
      currentIncrement,
      currentRatio,
      unit,
      filterNode,
    };
  },
};
</script>
          

<style scoped>
.energy-report-container {
  padding: 20px;
}

.query-container {
  margin-bottom: 20px;
}

.left-align {
  display: flex;
  align-items: center;
}

.card-header {
  font-weight: bold;
}

.chart-controls {
  text-align: right;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.current {
  margin-bottom: 10px;
}
.current span {
  padding: 0px 10px 0px 0px;
}
</style>
