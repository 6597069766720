<template>
  <div class="common-layout">
    <el-container>
      <el-main>
        <el-card shadow="always">
          <div class="query-container">
            <el-input v-model="input" style="width: 200px" placeholder="请输入设备名" clearable/>
            <!-- 时间选择-->
            <el-date-picker
                v-model="value"
                type="daterange"
                range-separator="到"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :default-time="defaultTime"
                size="medium"
                style="width: 280px; margin-left: 20px;"
            />
            <el-button type="primary" :icon="Search" @click="handleQueryClick"
                       style="margin-left: 20px;">查询
            </el-button>
            <el-button type="primary" :icon="Plus" style="margin-left: 20px;" @click="openAddDialog">新增</el-button>
            <el-button type="primary" @click="batchDelete" style="margin-left: 10px; width: 80px;">批量删除</el-button>
          </div>
          <!-- 表格 -->
          <el-table
              :data="currentTableData"
              style="width: 100%"
              @row-click="handleRowClick"
              @selection-change="handleSelectionChange"
              show-selection
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="id" label="id" align="center" width="100px" sortable/>
            <el-table-column prop="name" label="设备名" align="center" sortable/>
            <el-table-column prop="oldEndTime" label="旧表结束时间" align="center" sortable/>
            <el-table-column prop="oldEndValue" label="旧表结束值" align="center" sortable/>
            <el-table-column prop="newStartTime" label="新表启用时间" align="center" sortable/>
            <el-table-column prop="newStartValue" label="新表启用值" align="center" sortable/>
            <el-table-column label="操作">
              <template #default="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button size="mini" type="text" block @click="editPop(scope.row)">查看</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页组件放右边 -->
          <div class="pagination-container">
            <!-- 分页组件 -->
            <el-pagination
                class="pagination"
                :current-page="currentPage"
                :page-size="pageSize"
                :page-sizes="[10, 20, 30, 50, 100]"
                :total="totalRecords"
                layout="total, prev, pager, next, jumper, sizes"
                @current-change="handlePageChange"
                @size-change="handlePageSizeChange"
            />
          </div>


          <!--【新增弹窗】-->
          <el-dialog
              title="新增换表记录"
              v-model="addDialogVisible"
              width="700px"
              :destroy-on-close="true"
              draggable
          >
            <el-form
                :model="ruleForm"
                :rules="rules"
                ref="formRef"
                label-width="120px"
                class="demo-ruleForm"
            >

              <!-- 设备名 -->
              <el-form-item label="设备名" prop="name" class="form-item">
                <el-select
                    v-model="ruleForm.name"
                    placeholder="请选择设备名"
                    clearable
                    @change="handleDeviceChange"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in equipmentList"
                      :key="item.equipmentId"
                      :label="item.equipmentName"
                      :value="item.equipmentName"
                  />
                </el-select>
              </el-form-item>

              <!-- 老表结束时间 -->
              <el-form-item label="旧表结束时间" prop="oldEndTime" required class="form-item">
                <el-date-picker
                    v-model="ruleForm.oldEndTime"
                    type="datetime"
                    placeholder="选择日期和时间"
                    style="width: 100%"
                    value-format="YYYY-MM-DD HH:mm:ss"
                />
              </el-form-item>

              <!-- 老表结束的值 -->
              <el-form-item label="旧表结束值" prop="oldEndValue" class="form-item">
                <el-input
                    v-model="ruleForm.oldEndValue"
                    placeholder="请输入旧表结束的值"
                    clearable
                />
              </el-form-item>

              <!-- 右侧表单 -->
              <!-- 新表启用时间 -->
              <el-form-item label="新表启用时间"  prop="newStartTime" required class="form-item">
                <el-date-picker
                    v-model="ruleForm.newStartTime"
                    type="datetime"
                    placeholder="选择日期和时间"
                    style="width: 100%"
                    value-format="YYYY-MM-DD HH:mm:ss"
                />
              </el-form-item>

              <!-- 新表启用值 -->
              <el-form-item label="新表启用值" prop="newStartValue" class="form-item">
                <el-input
                    v-model="ruleForm.newStartValue"
                    placeholder="请输入新表启用值"
                    clearable
                />
              </el-form-item>


              <!-- 历史记录表格 -->
              <div class="history-table">
                <div class="table-header">

                </div>
                <el-table
                    :data="currentTableData1"
                    style="width: 100%"
                    :max-height="300"
                    border
                    stripe
                    highlight-current-row
                    :header-cell-style="headerCellStyle"
                    :cell-style="cellStyle"
                    @row-click="handleRowClick1"
                >
                  <el-table-column
                      prop="id"
                      label="ID"
                      width="70"
                      align="center"
                  />
                  <el-table-column
                      prop="name"
                      label="设备名称"
                      min-width="120"
                      align="left"
                      show-overflow-tooltip
                  />
                  <el-table-column
                      prop="oldEndTime"
                      label="旧表结束时间"
                      min-width="160"
                      align="center"
                  />
                  <el-table-column
                      prop="oldEndValue"
                      label="旧表结束值"
                      min-width="100"
                      align="right"
                  />
                  <el-table-column
                      prop="newStartTime"
                      label="新表启用时间"
                      min-width="160"
                      align="center"
                  />
                  <el-table-column
                      prop="newStartValue"
                      label="新表启用值"
                      min-width="100"
                      align="right"
                  />
                  <el-table-column label="操作" width="150" fixed="right">
                    <template #default="scope">
                      <el-row>
                        <el-col :span="12">
                          <el-button
                              size="small"
                              type="text"
                              @click.stop="handleHistoryEdit(scope.row)"
                          >
                            编辑
                          </el-button>
                        </el-col>
                        <el-col :span="12">
                          <el-popconfirm
                              title="确定删除此记录?"
                              @confirm="handleHistoryDelete(scope.row)"
                              confirm-button-text="确定"
                              cancel-button-text="取消"
                          >
                            <template #reference>
                              <el-button
                                  size="small"
                                  type="text"
                                  style="color: #F56C6C"
                              >
                                删除
                              </el-button>
                            </template>
                          </el-popconfirm>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-form>

            <!-- 底部按钮 -->
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
              </div>
            </template>
          </el-dialog>

          <!-- 编辑弹窗 -->
          <el-dialog
              title="编辑换表记录"
              v-model="editDialogVisible"
              width="700px"
              :destroy-on-close="true"
              draggable
              class="edit-dialog"
          >
            <el-form
                :model="editForm"
                :rules="rules"
                ref="editFormRef"
                label-width="120px"
            >
              <!-- 设备名 -->
              <el-form-item label="设备名" prop="name" class="form-item">
                <el-input
                    v-model="editForm.name"
                    placeholder="设备名称"
                    disabled
                    class="disabled-input"
                />
              </el-form-item>

              <!-- 老表结束时间 -->
              <el-form-item label="旧表结束时间" required class="form-item">
                <el-date-picker
                    v-model="editForm.oldEndTime"
                    type="datetime"
                    placeholder="选择日期和时间"
                    style="width: 100%"
                    value-format="YYYY-MM-DD HH:mm:ss"
                />
              </el-form-item>

              <!-- 老表结束的值 -->
              <el-form-item label="旧表结束值" prop="oldEndValue" class="form-item">
                <el-input
                    v-model="editForm.oldEndValue"
                    placeholder="请输入旧表结束的值"
                    clearable
                />
              </el-form-item>

              <!-- 新表启用时间 -->
              <el-form-item label="新表启用时间" required class="form-item">
                <el-date-picker
                    v-model="editForm.newStartTime"
                    type="datetime"
                    placeholder="选择日期和时间"
                    style="width: 100%"
                    value-format="YYYY-MM-DD HH:mm:ss"
                />
              </el-form-item>

              <!-- 新表启用值 -->
              <el-form-item label="新表启用值" prop="newStartValue" class="form-item">
                <el-input
                    v-model="editForm.newStartValue"
                    placeholder="请输入新表启用值"
                    clearable
                />
              </el-form-item>
            </el-form>

            <!-- 底部按钮 -->
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="closeEditDialog">取 消</el-button>
                <el-button type="primary" @click="submitEditForm">确 定</el-button>
              </div>
            </template>
          </el-dialog>

          <!--批量删除-->
          <el-dialog
              title=""
              v-model="deleteDialogVisible"
              width="26%"
          >
            <!-- 自定义标题部分 -->
            <template #header>
              <div style="display: flex; align-items: center; justify-content: flex-start; width: 100%;">
                <span>删除</span>
              </div>
            </template>

            <!-- 对话框内容 -->
              <el-icon style="color: orange; font-size: 20px; margin-right: 5px;">
                <QuestionFilled/>
              </el-icon>
              <span>此操作将永久删除选中的数据，是否继续？</span>


            <!-- 底部按钮 -->
            <template v-slot:footer>
              <el-button @click="deleteDialogVisible = false">取 消</el-button>
              <el-button
                  type="primary"
                  :loading="isDeleting"
                  @click="deleteRecordsConfig"
              >
                确 定
              </el-button>

            </template>
          </el-dialog>

          <!-- 查看-->
          <el-dialog title="查看换表记录"
                     v-model="deleteDialogVisibleTable"
                     width="50%">
            <!-- 历史记录表格 -->
            <div class="history-table">
              <el-table
                  :data="currentTableData1"
                  style="width: 100%"
                  :max-height="300"
                  border
                  stripe
                  highlight-current-row
              >
                <el-table-column prop="id" label="ID" width="80" align="center"/>
                <el-table-column prop="name" label="设备名称" align="center"/>
                <el-table-column prop="oldEndTime" label="旧结束时间" align="center"/>
                <el-table-column prop="oldEndValue" label="旧表结束值" align="center"/>
                <el-table-column prop="newStartTime" label="新表启用时间" align="center"/>
                <el-table-column prop="newStartValue" label="新表启用值" align="center"/>
                <el-table-column label="操作" width="100" align="center">
                  <template #default="scope">
                    <el-row>
                      <el-col :span="12">
                        <el-button
                            size="small"
                            type="text"
                            @click="editPop1(scope.row)">
                          编辑
                        </el-button>
                      </el-col>
                      <el-col :span="12">
                        <el-popconfirm
                            title="确定删除?"
                            @confirm="handleDelete(scope.row)">
                          <template #reference>
                            <el-button
                                size="small"
                                type="text"
                                block
                                style="color: red">
                              删除
                            </el-button>
                          </template>
                        </el-popconfirm>
                      </el-col>
                    </el-row>
                  </template>
                </el-table-column>

              </el-table>
            </div>
          </el-dialog>

        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import apiModule from "@/APIConfig";
import axios from "axios";
import {Plus, Search} from "@element-plus/icons-vue";
import ApiConfig from "@/APIConfig";
import {ElMessage} from "element-plus";


// 【页面加载时触发查询】
onMounted(() => {
  queryCustomRegionPeakValleyLevelDay();//查询记录表
});
//【请输入设备名】
const input = ref('')
//【时间选择】
const value = ref('')
const defaultTime = ref < [Date, Date] > ([
  new Date(2000, 1, 1, 0, 0, 0),
  new Date(2000, 2, 1, 23, 59, 59),
])

//【表格】
const selectedData = ref([]);// 用于存储选中的表格行
const currentTableData = ref([])//表格数据
const handleRowClick = (rows) => {// 表格选中项变化时触发
  selectedData.value = rows
};

//【分页】
const currentPage = ref(1);
const pageSize = ref(10); // 每页10条数据
const totalRecords = ref(0); // 总记录数
const handlePageChange = (page) => {// 处理每页条数变化
  currentPage.value = page;
  queryCustomRegionPeakValleyLevelDay(); // 每次分页改变时重新加载数据
};
const handlePageSizeChange = (size) => {// 处理页条数变化
  pageSize.value = size;
  queryCustomRegionPeakValleyLevelDay(); // 每次页大小改变时重新加载数据
};

//【查询按钮】
const handleQueryClick = async () => {
  currentPage.value = 1;
  await queryCustomRegionPeakValleyLevelDay();
};
//【查询记录表】
const queryCustomRegionPeakValleyLevelDay = async () => {
  try {
    const equipmentName = input.value || defaultTime.value; // 假设你用 `input` 变量绑定了设备名  // 获取设备名
    const [startTime, endTime] = value.value && value.value.length > 0 ? value.value : [null, null]; // 获取时间范围
    const formattedStartTime = startTime ? formatDate(startTime) : null;// 格式化时间，假设使用的是 moment 或类似的库，如果没有可以直接使用 Date 对象
    const formattedEndTime = endTime ? formatDate(endTime) : null;
    console.log(formattedStartTime + "-------------" + formattedEndTime)
    // 请求参数
    const params = {
      page: currentPage.value,  // 当前页码
      limit: pageSize.value,    // 每页显示条数
      equipmentName,            // 设备名
      startTime: formattedStartTime,  // 开始时间
      endTime: formattedEndTime     // 结束时间
    };
    // 发送请求到端
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.switchingTableRecordsNew}`, {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data.code === 0 && response.data.data) {    // 处理返回的数据
      currentTableData.value = response.data.data.records;  // 获取分页后的数据
      totalRecords.value = response.data.data.total || 0;   // 获取总记录数
    } else {
      console.error('API returned an error or empty data');
    }
    return response.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
const formatDate = (date) => {//【查询记录表】开始时间-结束时间 时间转换
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，+1
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

//【表单删除】根据id删除数据---------------------------------------------------------------
const handleDelete = (row) => {
  axios
      .post(
          `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.deleteSwitchingTableRecordById}`, // 修改为 POST 请求
          row, // 将整个行数据作为请求体发送
          {
            headers: { 'Content-Type': 'application/json' }, // 确保发送 JSON 数据
          }
      ).then((response) => {
        if (response.data && response.data.code === 0) {
          if (response.data.message === "删除成功") {
            ElMessage.success(response.data.message);
            queryCustomRegionPeakValleyLevelDay1(row.name);
            queryCustomRegionPeakValleyLevelDay();
          } else {
            ElMessage.error(response.data.message);
            queryCustomRegionPeakValleyLevelDay();
            queryCustomRegionPeakValleyLevelDay1(row.name);
          }
        }
      })
      .catch((error) => {
        console.error('删除换表记录发生错误', error.response || error.message || error);
        ElMessage.error('删除换表记录发生错误');
        queryCustomRegionPeakValleyLevelDay1(row.name);
      });

};

//【新增弹窗】---------------------------------------------------------------------------
const addDialogVisible = ref(false);
const openAddDialog = () => {// 打开弹窗
  fetchEquipmentData();
  addDialogVisible.value = true;
}

// 关闭弹窗并重置表单
const closeDialog = () => {
  addDialogVisible.value = false;
  // 重置表单数据
  ruleForm.value = {
    name: '',
    oldEndTime: '',
    oldEndValue: '',
    newStartTime: '',
    newStartValue: '',
  };
  // 如果需要,也可以清除表单的验证结果
  if (formRef.value) {
    formRef.value.resetFields();
  }
};

// 【新增弹窗】设备名
const handleDeviceChange = (selectedName) => {
const selectedDevice = equipmentList.value.find(item => item.equipmentName === selectedName);
const equipmentId = selectedDevice.equipmentId;
queryCustomRegionPeakValleyLevelDay1(ruleForm.value.name); // 调用请求方法
selectLatestRecordByEquipmentIdAndTime(equipmentId);
};

//【新增弹窗】根据设备id 查询点位值
const selectLatestRecordByEquipmentIdAndTime = async (row) => {
  try {
    const response = await axios.get(`${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.selectLatestRecordByEquipmentIdAndTime}?equipmentId=${row}`, {});
    if (response.data.code === 0) {
      const data = response.data.data[0];
      console.log(data)
      ruleForm.value = {
        ...ruleForm.value, // 保留现有值
        oldEndTime: data.lastSavedTime, // 设置新的开始时间
        oldEndValue: data.lastSavedValue ,
      };

    }
  } catch (error) {
    ElMessage.error('查询失败');
  }
};
//【新增弹窗】 查询全部设备，只显示前10条
const queryCustomRegionPeakValleyLevelDay1 = async (name) => {
  try {
    // 请求参数
    const params = {
      page: 1,  // 当前页码
      limit: 10,    // 每页显示条数
      equipmentName: name,            // 设备名
    };
    // 发送请求到后端
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.switchingTableRecords}`, {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data.code === 0 && response.data.data) {    // 处理返回的数据
      currentTableData1.value = response.data.data.records;  // 获取分页后的数据
    } else {
      console.error('API returned an error or empty data');
    }
    return response.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
//【新增弹窗】表格
const selectedData1 = ref([]);// 用于存储选中的表格行
const currentTableData1 = ref([])//表格数据
const handleRowClick1 = (rows) => {// 表格选中项变化时触发
  selectedData1.value = rows
};
const equipmentList = ref([]); // 用来存放设备数据
const fetchEquipmentData = async () => { //查询全部设备
  try {
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.selectAllEquipment}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data.code === 0) {
      equipmentList.value = response.data.data; // 更新设备列表
    } else {
      console.error('获取设备数据失败:', response.data.message);
    }
  } catch (error) {
    console.error('请求失败:', error);
  }
};
//【新增】 表单数据
const ruleForm = ref({
  name: '',
  oldEndTime: '',
  oldEndValue: '',
  newStartTime: '',
  newStartValue: '',
});
// 【新增】表单验证规则
const rules = {
  name: [
    {required: true, message: '请选择设备名', trigger: 'change'},
  ],
  oldEndTime: [
    {type: 'date', required: true, message: '选择日期和时间', trigger: 'change'}
  ],
  oldEndValue: [
    {required: true, message: '请输入旧表结束值', trigger: 'blur'}, {
      pattern: /^[0-9]+(\.[0-9]+)?$/,  // 正则表达式，匹配整数或小数
      message: '请输入有效的整数或小数',
      trigger: 'blur'
    }
  ],
  newStartTime: [
    {type: 'date', required: true, message: '选择日期和时间', trigger: 'change'}
  ],
  newStartValue: [
    {required: true, message: '请输入新表启用值', trigger: 'blur'}, {
      pattern: /^[0-9]+(\.[0-9]+)?$/,  // 正则表达式，匹配整数或小数
      message: '请输入有效的整数或小数',
      trigger: 'blur'
    }
  ],
};
// 【新增【确定按钮】
const formRef = ref(null);
const message = ref('');
//【新增【确定按钮】 提交表单
const submitForm = () => {
  ///校验完
  formRef.value.validate(() => {
    addConfirm();
  });
};
//【新增【确定按钮】
const addConfirm = async () => {
  try {
    // 获取老表和新表的日期与时间
    const oldEndDate = ruleForm.value.oldEndTime;
    const newStartDate = ruleForm.value.newStartTime;

    // 清理 name 字段，确保没有多余的空格或符号
    const name = ruleForm.value.name.trim(); // 去除前后空格
    const selectedDevice = equipmentList.value.find(item => item.equipmentName === name);

    // 请求数据
    const requestData = {
      name: name, // ��用清理后的 name
      equipmentId: selectedDevice.equipmentId,
      oldEndTime: oldEndDate,
      oldEndValue: ruleForm.value.oldEndValue,
      newStartTime: newStartDate,
      newStartValue: ruleForm.value.newStartValue
    };
    const response = await axios.post(`${apiModule.apiBaseUrl}${apiModule.endpoints.insertSwitchingTableRecord}`, requestData, {
      headers: {'Content-Type': 'application/json'},
    });

    if (response.data.code === 0) {
      message.value = response.data.message; // 更新设备列表
      console.log(message.value);

      if (message.value.includes('内有换表记录')) {
        ElMessage.warning(response.data.message);
      } else if (message.value === '插入成功') {
        ElMessage.success(response.data.message);
        addDialogVisible.value = false;
        resetForm();
      } else {
        ElMessage.error(response.data.message);
      }
      queryCustomRegionPeakValleyLevelDay();
      queryCustomRegionPeakValleyLevelDay1(ruleForm.value.name);
    } else {
      console.error('获取设备数据失败:', response.data.message);
    }
  } catch (error) {
    console.error('请求失败:', error);
  }
};
// 清空表单数据的函数
const resetForm = () => {
  ruleForm.value = {
    name: '',
    oldEndTime: '',
    newStartTime: '',
    oldEndValue: '',
    newStartValue: ''
  };
};
//【查看】------------------------------------------------------------------------------------------
const deleteDialogVisibleTable= ref(false);
// 打开【查看弹窗】
const editPop = (row) => {
  // editForm.value = {
  //   id: row.id,
  //   name: row.name,
  //   oldEndTime: row.oldEndTime,
  //   oldEndValue: row.oldEndValue,
  //   newStartTime: row.newStartTime,
  //   newStartValue: row.newStartValue,
  //   equipmentId: row.equipmentId
  // };
  // editDialogVisible.value = true;
  deleteDialogVisibleTable.value = true;
  queryCustomRegionPeakValleyLevelDay1(row.name);
};

// 【编辑】-------------------------------------------------------------------
const editDialogVisible = ref(false);
const editFormRef = ref(null); // 添加 editFormRef 的定义
const editForm = ref({
  id: '', // 添加 id 字段
  name: '',
  oldEndTime: '',
  oldEndValue: '',
  newStartTime: '',
  newStartValue: '',
  equipmentId: '' // 加 equipmentId 字段
});

const editPop1 = (row) => {
  editForm.value = {
    id: row.id,
    name: row.name,
    oldEndTime: row.oldEndTime,
    oldEndValue: row.oldEndValue,
    newStartTime: row.newStartTime,
    newStartValue: row.newStartValue,
    equipmentId: row.equipmentId
  };
  editDialogVisible.value = true;
  queryCustomRegionPeakValleyLevelDay1(row.name);
  queryCustomRegionPeakValleyLevelDay();
};
// 关闭【编辑弹窗】
const closeEditDialog = () => {
  editDialogVisible.value = false;
  // 重置表单数据
  editForm.value = {
    id: '',
    name: '',
    oldEndTime: '',
    oldEndValue: '',
    newStartTime: '',
    newStartValue: '',
    equipmentId: ''
  };
  // 清除表单的验证结果
  if (editFormRef.value) {
    editFormRef.value.resetFields();
  }
  // 如果是从新增弹窗打开的编辑，则重新打开新增弹窗
  if (addDialogVisible.value === false && ruleForm.value.name) {
    addDialogVisible.value = true;
  }
};
// 【编辑弹窗【确认按钮】
const submitEditForm = () => {
  editFormRef.value?.validate(async (valid) => {
    if (valid) {
      try {
        const response = await axios.post(
            `${apiModule.apiBaseUrl}${apiModule.endpoints.updateSwitchingTableRecord}`,
            {
              id: editForm.value.id,
              name: editForm.value.name,
              equipmentId: editForm.value.equipmentId,
              oldEndTime: editForm.value.oldEndTime,
              oldEndValue: editForm.value.oldEndValue,
              newStartTime: editForm.value.newStartTime,
              newStartValue: editForm.value.newStartValue
            }
        );

        if (response.data.code === 0) {

          if (response.data.message.includes('更新成功')) {
            ElMessage.success('更新成功');
            editDialogVisible.value = false;
          } else {
            ElMessage.error(response.data.message);
          }
          // 刷新表格数据
          queryCustomRegionPeakValleyLevelDay();
          // 如果需要刷新历史记录
          if (editForm.value.name) {
            queryCustomRegionPeakValleyLevelDay1(editForm.value.name);
          }
        } else {
          ElMessage.error(response.data.message || '更新失败');
        }
      } catch (error) {
        console.error('更新失败:', error);
        ElMessage.error('更新失败');
      }
    }
  });
};
// 【新增弹窗【编辑】历史记录编辑
const handleHistoryEdit = (row) => {
  editForm.value = {
    id: row.id,
    name: row.name,
    oldEndTime: row.oldEndTime,
    oldEndValue: row.oldEndValue,
    newStartTime: row.newStartTime,
    newStartValue: row.newStartValue,
    equipmentId: row.equipmentId
  };
  // 关闭新增弹窗
  // addDialogVisible.value = false;
  // 打开编辑弹窗
  editDialogVisible.value = true;
};
// 【新增弹窗【删除】历史记录删除
const handleHistoryDelete = async (row) => {
  try {
    const response = await axios
        .post(
            `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.deleteSwitchingTableRecordById}`, // 修改为 POST 请求
            row, // 将整个行数据作为请求体发送
            {
              headers: { 'Content-Type': 'application/json' }, // 确保发送 JSON 数据
            }
        )
    if (response.data.code === 0) {
      queryCustomRegionPeakValleyLevelDay1(row.name);
      ElMessage.success('删除成功');
      // 刷新主表格数据
      queryCustomRegionPeakValleyLevelDay();
    } else {
      ElMessage.error(response.data.message || '删除失败');
    }
  } catch (error) {
    console.error('删除失败:', error);
    ElMessage.error('删除失败');
  }
};

//【批量删除】接口--------------------------------------------------------------------------------------------------
const isDeleting = ref(false)//是否加载【图标】
const deleteDialogVisible = ref(false)//删除【窗口】
const selectedRows = ref([]);// 选中的行数据
const handleSelectionChange = (val) => {// 处理选中行变化
  selectedRows.value = val;
};
const batchDelete = () => {// 【批量删除】打开弹窗
  // 检查是否有选中的行
  if (selectedRows.value.length === 0) {
    ElMessage({
      message: `没有选中的行！`,
      type: 'error',
    });
    return;
  }
  deleteDialogVisible.value = true;  // 设置为 true，弹窗就会显示
};
const deleteRecordsConfig = async () => {
  isDeleting.value = true;  // 开始加载
  try {
    // 提取选中行的 ID
    const recordsToDelete = selectedRows.value;
    // 发起删除请求
    const response = await axios.post(
        `${apiModule.apiBaseUrl}${apiModule.endpoints.deleteSwitchingTableRecordsByIds}`,
        recordsToDelete,
        {headers: {'Content-Type': 'application/json'}}
    );
    // 根据返回的 code 判断操作结果
    if (response.data.code === 0) {
      ElMessage({ // 操作成功
        message: response.data.message,
        type: 'success',
      });
    } else if (response.data.code === 1) {
      ElMessage({ // 操作失败
        message: `操作失败: ${response.data.message}`,
        type: 'error',
      });
    } else {
      ElMessage({ // 其他错误处理
        message: `未知错误: ${response.data.message}`,
        type: 'error',
      });
    }
    queryCustomRegionPeakValleyLevelDay();    // 重新查询数据
  } catch (err) {
    console.error("Error deleting records:", err);    // 捕获异常并显示错误提示
    ElMessage({
      message: "网络错误，请稍后重试",
      type: 'error',
    });
    queryCustomRegionPeakValleyLevelDay();   // 重新查询数据，确保操作不会卡死
  } finally {
    isDeleting.value = false;  // 请求结束，隐藏加载动画
    deleteDialogVisible.value = false;  // 关闭对话框
  }
};


</script>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  font-size: 32px;
  font-weight: 600;
}

.query-container {
  text-align: left;
  margin-bottom: 20px;
}

/*【分页】*/
.pagination {
  float: right; /* 将分页组件浮动到右边 */
  margin-top: 20px; /* 可以根据需要调整分页组件与表格之间的距离 */
}

.pagination-container {
  display: flex;
  justify-content: flex-end; /* 可改为 flex-start 或 flex-end 调整位置 */
}

/* 新增样式 */
.form-item {
  margin-bottom: 22px;
}

.history-table {
  margin-top: 20px;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
}

.table-header {
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  background-color: #F5F7FA;
  border-bottom: 1px solid #EBEEF5;
  border-radius: 4px 4px 0 0;
}

.dialog-footer {
  padding-top: 20px;
  text-align: right;
}

:deep(.el-form-item__label) {
  font-weight: 500;
}

:deep(.el-input__inner) {
  height: 36px;
}

.edit-dialog .el-dialog__header {
  margin-right: 0;
  padding: 20px;
  border-bottom: 1px solid #EBEEF5;
}

.edit-dialog .el-dialog__footer {
  border-top: 1px solid #EBEEF5;
  padding: 20px;
}

.edit-form .form-content {
  display: flex;
  justify-content: space-between;
}

.edit-form .form-item {
  margin-bottom: 22px;
}

.edit-form .disabled-input {
  background-color: #F5F7FA;
  border-color: #EBEEF5;
  color: #606266;
}

</style>
